/* eslint-disable global-require */

import { Carousel } from "@releaseit/ui";

import type { ValueProp } from "../../types";

export const VALUE_PROPS: ValueProp[] = [
  {
    icon: require("@/assets/icons/lightbulb.svg").default,
    text: "Rethink consumerism",
  },
  {
    icon: require("@/assets/icons/waste.svg").default,
    text: "Do more, waste less",
  },
  {
    icon: require("@/assets/icons/planet.svg").default,
    text: "Protect the planet",
  },
  {
    icon: require("@/assets/icons/stars.svg").default,
    text: "Experience everything",
  },
  {
    icon: require("@/assets/icons/cash.svg").default,
    text: "Earn some extra cash",
  },
];

export function ValueProps() {
  return (
    <Carousel
      options={{ draggable: false }}
      autoplay={{ playOnInit: true, delay: 3000, stopOnMouseEnter: false }}
      className="
        [--space-between:20px]
        md:[--slides-per-view:3]
        lg:[--slides-per-view:5]
      "
      classNames={{
        carousel: "container",
        buttons: "md:hidden",
      }}
    >
      {VALUE_PROPS.map((valueProp) => (
        <div
          key={valueProp.text}
          className="flex items-center justify-center gap-4 py-8 px-8 md:flex-col md:px-0"
        >
          <valueProp.icon className="h-10 w-10" />
          <p className="text-base font-medium">{valueProp.text}</p>
        </div>
      ))}
    </Carousel>
  );
}
