/* eslint-disable jsx-a11y/no-static-element-interactions */

import NextImage from "next/image";
import NextLink from "next/link";

import { Carousel } from "@releaseit/ui";

import { analytics } from "@/utils/analytics";

import type { BannerCarouselImage } from "../../types";

const AUTOPLAY_DURATION_MS = 5000;

export type BannerCarouselProps = {
  /** The list of images to display in the carousel */
  images: BannerCarouselImage[];
};

// TODO: figure out how to use srcset with next/image
export function BannerCarousel({ images }: BannerCarouselProps) {
  return (
    <>
      <DesktopCarousel images={images} />
      <MobileCarousel images={images} />
    </>
  );
}

function DesktopCarousel({ images }: BannerCarouselProps) {
  return (
    <Carousel
      autoplay={{ playOnInit: true, delay: AUTOPLAY_DURATION_MS }}
      classNames={{
        carousel: "hidden md:block max-w-[1840px] mx-auto w-full",
        prevButton: "left-10",
        nextButton: "right-10",
      }}
    >
      {images.map((image, idx) => (
        <NextLink key={image.href} href={image.href} prefetch={false}>
          <a
            onClick={() =>
              analytics.trackEvent("home.carousel", {
                href: image.href,
                desktop: image.desktop,
              })
            }
          >
            <NextImage
              src={image.desktop}
              alt={`promo banner ${idx} desktop`}
              layout="responsive"
              priority={idx === 0}
              placeholder="blur"
              unoptimized
              objectFit="cover"
            />
          </a>
        </NextLink>
      ))}
    </Carousel>
  );
}

function MobileCarousel({ images }: BannerCarouselProps) {
  return (
    <Carousel
      autoplay={{ playOnInit: true, delay: AUTOPLAY_DURATION_MS }}
      classNames={{
        carousel: "block md:hidden",
        prevButton: "left-10",
        nextButton: "right-10",
      }}
    >
      {images.map((image, idx) => (
        <NextLink key={image.href} href={image.href} prefetch={false}>
          <a
            onClick={() =>
              analytics.trackEvent("home.carousel", {
                href: image.href,
                mobile: image.mobile,
              })
            }
          >
            <NextImage
              src={image.mobile}
              alt={`promo banner ${idx} mobile`}
              layout="responsive"
              priority={idx === 0}
              placeholder="blur"
              unoptimized
              objectFit="cover"
            />
          </a>
        </NextLink>
      ))}
    </Carousel>
  );
}
