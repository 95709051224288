import { analytics } from "@/utils/analytics";
import { CategoryTile, CategoryTileProps } from "@/features/category";

export type FeaturedCategoriesBlockProps = {
  /** The list of featured categories */
  categories: CategoryTileProps[];
};

export function FeaturedCategoriesBlock({ categories }: FeaturedCategoriesBlockProps) {
  return (
    <section className="container grid grid-cols-1 gap-3 sm:grid-cols-6 sm:gap-6 lg:gap-8">
      {categories.map((category, idx) => (
        <CategoryTile
          key={category.href}
          {...category}
          className={idx < 2 ? "sm:col-span-3" : "sm:col-span-2"}
          onClick={() => analytics.trackEvent("home.featured_categories", category)}
        />
      ))}
    </section>
  );
}
