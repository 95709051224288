import styles from "./BreakdownCard.module.css";

export interface BreakdownCardProps {
  /** Title of the card (displayed in the pill). */
  title: string;

  /** Subtitle to display below the icon. */
  subtitle: string;

  /** SVG image. */
  icon: React.ComponentType<React.ComponentPropsWithoutRef<"svg">>;

  /** List items to show in the styled steps list. */
  steps: React.ReactNode;
}

export default function BreakdownCard({ title, subtitle, icon: Icon, steps }: BreakdownCardProps) {
  return (
    <div className="relative pt-4">
      {/* Pill */}
      <span className="absolute top-0 left-1/2 -translate-x-1/2 rounded-full bg-primary-green px-3 py-1 font-medium text-black">
        {title}
      </span>

      {/* Contents */}
      <div className="flex flex-col items-center gap-y-8 rounded-lg border border-off-white bg-white p-8 pt-12 shadow-card">
        <Icon className="h-32 w-32" />
        <p className="mb-auto whitespace-pre-line text-center">{subtitle}</p>
        <ol className={styles.styledList}>{steps}</ol>
      </div>
    </div>
  );
}
