import { ErrorBoundary } from "react-error-boundary";
import { GetStaticProps, NextPage } from "next";
import dynamic from "next/dynamic";

import { Seo } from "@/components";
import { type CategoryTileProps } from "@/features/category";
import {
  type BannerCarouselImage,
  type CategoryRow,
  type FeaturedInLogo,
  CategoryRowsBlock,
  FeaturedCategoriesBlock,
  FeaturedInBlock,
  getHomeContent,
  HeroBlock,
  HowItWorksBlock,
  ListAnItemBlock,
  RecommendationsBlock,
  useNewsletterDialog,
} from "@/features/content";

const NewsletterDialog = dynamic(
  () => import("@/features/content/components/newletter-dialog/NewsletterDialog"),
  { ssr: false }
);

type Props = {
  bannerImages: BannerCarouselImage[];
  featuredCategories: CategoryTileProps[];
  categoryRows: CategoryRow[];
  featuredInLogos: FeaturedInLogo[];
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const { bannerImages, featuredCategories, categoryRows, featuredInLogos } =
    await getHomeContent();

  return {
    props: {
      bannerImages,
      featuredCategories,
      categoryRows,
      featuredInLogos,
    },
  };
};

const HomePage: NextPage<Props> = ({
  bannerImages,
  featuredCategories,
  categoryRows,
  featuredInLogos,
}) => {
  const { hasSeenDialog } = useNewsletterDialog();

  return (
    <>
      <Seo />

      {!hasSeenDialog && <NewsletterDialog />}

      <HeroBlock bannerImages={bannerImages} />
      <FeaturedInBlock logos={featuredInLogos} />
      <HowItWorksBlock />
      <FeaturedCategoriesBlock categories={featuredCategories} />
      <ListAnItemBlock />
      <CategoryRowsBlock categoryRows={categoryRows} />
      <ErrorBoundary FallbackComponent={RecommendationsBlock.ErrorFallback}>
        <RecommendationsBlock />
      </ErrorBoundary>
    </>
  );
};

export default HomePage;
