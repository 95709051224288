import NextImage, { StaticImageData } from "next/image";
import NextLink from "next/link";

import { cn } from "@releaseit/ui";

export type CategoryTileProps = React.ComponentPropsWithoutRef<"a"> & {
  /** The category name. */
  name: string;

  /** The link to the category page. */
  href: string;

  /** The category image. */
  image: StaticImageData;

  /** The visual variant of the category tile */
  variant?: "display" | "square";
};

export function CategoryTile({
  name,
  image,
  href,
  variant = "display",
  className,
  ...props
}: CategoryTileProps) {
  return (
    <NextLink href={href} prefetch={false}>
      <a
        {...props}
        className={cn(
          "relative mt-1 block overflow-hidden rounded-lg transition hover:-translate-y-1 hover:shadow-card",
          className
        )}
      >
        <div
          className={
            variant === "display"
              ? "aspect-w-3 aspect-h-2 sm:aspect-w-4 sm:aspect-h-3"
              : "aspect-w-1 aspect-h-1"
          }
        >
          <NextImage
            src={image}
            alt={name}
            layout="fill"
            quality={100}
            objectFit="cover"
            objectPosition="top"
            placeholder="blur"
          />
        </div>

        <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/50 px-3 pb-2 pt-14">
          <h3 className="truncate text-xl font-medium text-white">{name}</h3>
        </div>
      </a>
    </NextLink>
  );
}
