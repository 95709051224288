import { CarouselRow } from "@/components";
import { useHomePageRecommendationsQuery } from "@/generated/graphql";
import { analytics } from "@/utils/analytics";
import { ListingCard, mapListingToCard } from "@/features/listings";

const RECOMMENDATIONS_STALE_TIME = 5 * 60 * 1000; // 5 minutes

export const HOME_PAGE_RECOMMENDATIONS_QUERY = /* GraphQL */ `
  query HomePageRecommendations {
    HomePageRecommendations {
      heading
      listings {
        ...ListingCardFragment
      }
    }
  }
`;

export function RecommendationsBlock() {
  const { data: sections } = useHomePageRecommendationsQuery(undefined, {
    staleTime: RECOMMENDATIONS_STALE_TIME,
    useErrorBoundary: true,
    select: (data) => data.HomePageRecommendations,
  });

  return (
    <section className="container mt-8 mb-12 space-y-8">
      {sections?.map((section) => (
        <CarouselRow heading={section.heading} key={section.heading}>
          {section.listings.map((item) => (
            <ListingCard
              key={item.listingId}
              {...mapListingToCard(item)}
              className="h-full"
              onClick={() => {
                const normalisedHeading = section.heading.toLowerCase().replace(/\s/g, "_");
                analytics.trackEvent(`home.${normalisedHeading}`, {
                  listingId: item.listingId,
                  title: item.title,
                });
              }}
            />
          ))}
        </CarouselRow>
      ))}
    </section>
  );
}

function ErrorFallback() {
  return (
    <div className="container mt-16">
      <div className="rounded-md border border-error bg-error/5 p-16 text-center">
        <p className="text-xl font-medium leading-loose">Something went wrong</p>
        <p className="text-gray-800">Unable to fetch latest recommendations</p>
      </div>
    </div>
  );
}

RecommendationsBlock.ErrorFallback = ErrorFallback;
