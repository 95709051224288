import NextImage from "next/image";
import NextLink from "next/link";

import ListAnItemDTImg from "@/assets/images/list-an-item-dt.webp";
import ListAnItemMImg from "@/assets/images/list-an-item-m.webp";

export function ListAnItemBlock() {
  return (
    <section className="container mt-8">
      <NextLink href="/create-listing">
        <a className="hidden sm:block">
          <NextImage
            src={ListAnItemDTImg}
            layout="responsive"
            className="rounded-lg"
            placeholder="blur"
            unoptimized
            alt="list an item"
          />
        </a>
      </NextLink>

      <NextLink href="/create-listing">
        <a className="block sm:hidden">
          <NextImage
            src={ListAnItemMImg}
            layout="responsive"
            className="rounded-lg"
            placeholder="blur"
            unoptimized
            alt="list an item"
          />
        </a>
      </NextLink>
    </section>
  );
}
