import { BannerCarousel, BannerCarouselProps } from "../banner-carousel/BannerCarousel";
import { ValueProps } from "../value-props/ValueProps";

export type HeroBlockProps = {
  bannerImages: BannerCarouselProps["images"];
};

export function HeroBlock({ bannerImages }: HeroBlockProps) {
  return (
    <section>
      <BannerCarousel images={bannerImages} />
      <ValueProps />
    </section>
  );
}
