import React from "react";

const STORAGE_KEY = "releaseit-newsletter-seen";
const DELAY = 5 * 1000;

export function useNewsletterDialog() {
  const hasSeenDialog = React.useMemo(() => {
    // Localstorage not available in SSR
    if (typeof window === "undefined") {
      return false;
    }

    return localStorage?.getItem(STORAGE_KEY) === "true";
  }, []);

  const [isShowing, setIsShowing] = React.useState(false);

  // Show the dialog after delay
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShowing(true);
      localStorage?.setItem(STORAGE_KEY, "true");
    }, DELAY);

    return () => clearTimeout(timeout);
  }, []);

  const close = React.useCallback(() => {
    setIsShowing(false);
  }, []);

  return { hasSeenDialog, isShowing, close };
}
