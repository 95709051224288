import { CarouselRow } from "@/components";
import { analytics } from "@/utils/analytics";
import { CategoryTile } from "@/features/category";

import { CategoryRow } from "../../types";

export type CategoryRowsBlockProps = {
  categoryRows: CategoryRow[];
};

export function CategoryRowsBlock({ categoryRows }: CategoryRowsBlockProps) {
  return (
    <section className="container mt-8 space-y-8">
      {categoryRows.map((row) => (
        <CarouselRow key={row.heading} heading={row.heading}>
          {row.categories.map((category) => (
            <CategoryTile
              key={category.href}
              {...category}
              variant="square"
              onClick={() => {
                const normalisedHeading = row.heading.toLowerCase().replace(/\s/g, "_");
                analytics.trackEvent(`home.${normalisedHeading}`, category);
              }}
            />
          ))}
        </CarouselRow>
      ))}
    </section>
  );
}
