import { Collapsible } from "@releaseit/ui";

import OwnersIcon from "../../assets/icons/owners.svg";
import SeekersIcon from "../../assets/icons/seekers.svg";
import GraphicBGImg from "../../assets/images/graphic-bg.svg?url";
import BreakdownCard from "../breakdown-card/BreakdownCard";

import styles from "./HowItWorksBlock.module.css";

export function HowItWorksBlock() {
  return (
    <section className="relative px-4 py-8 lg:py-12 ">
      <div
        className="absolute inset-0 -z-10 h-[864px] bg-cover bg-top bg-no-repeat"
        style={{ backgroundImage: `url(${GraphicBGImg})` }}
      />

      {/* Summary */}
      <h2 className="text-center text-4xl font-medium">How it works</h2>
      <p className="mx-auto mt-2 max-w-prose text-center">
        We&apos;ve made it as easy as possible to connect people with the things they need. Whether
        you&apos;re an owner with stuff to rent out or a seeker looking to rent stuff, check out our
        5 simple steps below.
      </p>

      {/* Cards */}
      <Collapsible className="mt-4 flex flex-col items-center">
        <Collapsible.Trigger className="p-2 text-center font-medium text-mid-green">
          Click to find out more &darr;
        </Collapsible.Trigger>

        <Collapsible.Content
          className={`${styles.content} mt-6 grid justify-center gap-8 px-4 lg:grid-cols-[repeat(2,374px)]`}
        >
          <BreakdownCard
            title="Item Owners"
            subtitle={"Pumped to list your first item on Releaseit?\nHere's how."}
            icon={OwnersIcon}
            steps={
              <>
                <li>Hit &apos;List item&apos;</li>
                <li>Add your item details</li>
                <li>Accept booking requests</li>
                <li>Arrange pick-up & drop-off</li>
                <li className="font-medium">Enjoy the extra cash 💸</li>
              </>
            }
          />

          <BreakdownCard
            title="Item Seekers"
            subtitle={"Ready to rent with Releaseit?\nFollow these 5 easy steps."}
            icon={SeekersIcon}
            steps={
              <>
                <li>Browse rentals</li>
                <li>Request to book</li>
                <li>Collect your rental</li>
                <li className="font-medium">Have an epic experience 💥</li>
                <li>Return it!</li>
              </>
            }
          />
        </Collapsible.Content>
      </Collapsible>
    </section>
  );
}
