import NextImage from "next/image";

import { FeaturedInLogo } from "../../types";

export type FeaturedInBlockProps = {
  logos: FeaturedInLogo[];
};

export function FeaturedInBlock({ logos }: FeaturedInBlockProps) {
  return (
    <section className="container mt-4 lg:mt-6">
      <h2 className="text-center text-2xl font-medium">As featured in...</h2>
      <div className="mx-auto mt-4 flex flex-wrap justify-center gap-2 md:flex-nowrap">
        {logos.map((logo) => (
          <a
            key={logo.label}
            href={logo.href}
            target="_blank"
            rel="noopener noreferrer"
            className="relative h-14 w-28 md:h-20 md:w-36 lg:h-24 lg:w-40"
          >
            <NextImage src={logo.image} alt={logo.label} layout="fill" objectFit="contain" />
          </a>
        ))}
      </div>
    </section>
  );
}
